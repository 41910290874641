import { SchemaFormInputsRegistry } from "@smallstack/widget-core";
import { CmsFormInputComponent } from "./cms-form-input/cms-form-input.component";
import { ConfigurablePropertyInputComponent } from "./configurable-property-input/configurable-property-input.component";

/**
 * This method can be called for adding all cms form inputs to the registry.
 */
export function addCmsFormInputComponents(schemaFormInputsRegistry: SchemaFormInputsRegistry): void {
  schemaFormInputsRegistry.addWidget("cms", CmsFormInputComponent as any);
  schemaFormInputsRegistry.addWidget("widget", CmsFormInputComponent as any);
  schemaFormInputsRegistry.addWidget("ConfigurableProperty", ConfigurablePropertyInputComponent);
}

import { Injectable } from "@angular/core";
import { CalendarEntriesStore, CalendarStore } from "@smallstack/calendar-components";
import { PdfTemplateStore } from "@smallstack/cms-components";
import {
  ConfigurationStore,
  EmailTemplateStore,
  ReplacementsStore,
  StoreRegistry
} from "@smallstack/common-components";
import { ContactCustomFieldStore, ContactsStore } from "@smallstack/crm-components";
import { FileStore } from "@smallstack/file-components";
import { LocaleStore } from "@smallstack/i18n-components";
import { PriceZoneStore, ProductsStore } from "@smallstack/product-components";
import {
  TYPE_AUDIT_LOGS,
  TYPE_CALENDARS,
  TYPE_CALENDAR_ENTRIES,
  TYPE_CMS_PAGES,
  TYPE_COMPANIES,
  TYPE_CONFIGURATIONS,
  TYPE_CONTACTS,
  TYPE_CONTACTS_CUSTOM_FIELDS,
  TYPE_COUNTRIES,
  TYPE_CRONJOBS,
  TYPE_CUSTOMERS,
  TYPE_EMAIL_TEMPLATES,
  TYPE_FILES,
  TYPE_LANGUAGES,
  TYPE_LOCALES,
  TYPE_MAILING_CHANNELS,
  TYPE_MAILING_LISTS,
  TYPE_PDF_TEMPLATES,
  TYPE_PRICE_ZONES,
  TYPE_PRODUCTS,
  TYPE_PROJECTS,
  TYPE_REPLACEMENTS,
  TYPE_TRANSLATIONS,
  TYPE_USERS,
  TYPE_WORKFLOWS
} from "@smallstack/typesystem";
import { CustomerStore, ProjectUserStore } from "@smallstack/user-components";
import { currentProjectId$ } from "../../app.subscriptions";
import { CompanyUserStore } from "../common/store/company-user.store";
import { CronjobStore } from "../common/store/cronjob.store";
import { ProjectTranslationStore } from "../common/store/project-translation.store";
import { WorkflowStore } from "../common/store/workflow.store";
import { AuditLogStore } from "./audit-log/audit-log.store";
import { CmsPagesStore } from "./cms/pages/cms-pages.store";
import { CountriesStore } from "./i18n/countries.store";
import { LanguagesStore } from "./i18n/languages.store";
import { MailingChannelStore } from "./mailing/mailing-channel.store";
import { MailingListStore } from "./mailing/mailing-list.store";

@Injectable({ providedIn: "root" })
export class InitService {
  constructor(
    private auditLogStore: AuditLogStore,
    private calendarEntriesStore: CalendarEntriesStore,
    private calendarStore: CalendarStore,
    private contactStore: ContactsStore,
    private cmsPagesStore: CmsPagesStore,
    private companyUserStore: CompanyUserStore,
    private countriesStore: CountriesStore,
    private customerStore: CustomerStore,
    private emailTemplateStore: EmailTemplateStore,
    private languagesStore: LanguagesStore,
    private localeStore: LocaleStore,
    private productStore: ProductsStore,
    private projectTranslationStore: ProjectTranslationStore,
    private projectUserStore: ProjectUserStore,
    private storeRegistry: StoreRegistry,
    private mailingChannelStore: MailingChannelStore,
    private mailingListStore: MailingListStore,
    private fileStore: FileStore,
    private workflowStore: WorkflowStore,
    private pdfTemplateStore: PdfTemplateStore,
    private priceZoneStore: PriceZoneStore,
    private cronjobStore: CronjobStore,
    private contactCustomFieldStore: ContactCustomFieldStore,
    private replacementsStore: ReplacementsStore,
    private configurationStore: ConfigurationStore
  ) {}

  /**
   * Register stores automatically in the backoffice module.
   */
  public registerStores(): void {
    this.storeRegistry.registerStore("CompanyUser", this.companyUserStore);
    this.storeRegistry.registerStore(TYPE_AUDIT_LOGS, this.auditLogStore);
    this.storeRegistry.registerStore(TYPE_CALENDAR_ENTRIES, this.calendarEntriesStore);
    this.storeRegistry.registerStore(TYPE_CALENDARS, this.calendarStore);
    this.storeRegistry.registerStore(TYPE_CMS_PAGES, this.cmsPagesStore);
    this.storeRegistry.registerStore(TYPE_CONFIGURATIONS, this.configurationStore);
    this.storeRegistry.registerStore(TYPE_CONTACTS_CUSTOM_FIELDS, this.contactCustomFieldStore);
    this.storeRegistry.registerStore(TYPE_CONTACTS, this.contactStore);
    this.storeRegistry.registerStore(TYPE_COUNTRIES, this.countriesStore);
    this.storeRegistry.registerStore(TYPE_CRONJOBS, this.cronjobStore);
    this.storeRegistry.registerStore(TYPE_CUSTOMERS, this.customerStore);
    this.storeRegistry.registerStore(TYPE_EMAIL_TEMPLATES, this.emailTemplateStore);
    this.storeRegistry.registerStore(TYPE_FILES, this.fileStore);
    this.storeRegistry.registerStore(TYPE_LANGUAGES, this.languagesStore);
    this.storeRegistry.registerStore(TYPE_LOCALES, this.localeStore);
    this.storeRegistry.registerStore(TYPE_MAILING_CHANNELS, this.mailingChannelStore);
    this.storeRegistry.registerStore(TYPE_MAILING_LISTS, this.mailingListStore);
    this.storeRegistry.registerStore(TYPE_PDF_TEMPLATES, this.pdfTemplateStore);
    this.storeRegistry.registerStore(TYPE_PRICE_ZONES, this.priceZoneStore);
    this.storeRegistry.registerStore(TYPE_PRODUCTS, this.productStore);
    this.storeRegistry.registerStore(TYPE_REPLACEMENTS, this.replacementsStore);
    this.storeRegistry.registerStore(TYPE_TRANSLATIONS, this.projectTranslationStore);
    this.storeRegistry.registerStore(TYPE_USERS, this.projectUserStore);
    this.storeRegistry.registerStore(TYPE_WORKFLOWS, this.workflowStore);

    // reset stores on project change
    void currentProjectId$.subscribe(async (currentProjectId: string) => {
      // exclude some stores that should not be reset when a user changes projects
      const projectSwitchingExcludedStores = [TYPE_PROJECTS, TYPE_COMPANIES];
      const allStores = this.storeRegistry.getAllStores();
      for (const storeName in allStores) {
        if (projectSwitchingExcludedStores.includes(storeName)) continue;
        const store = allStores[storeName];
        await store.reset();
      }
    });
  }
}

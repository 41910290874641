import { Injectable, OnDestroy } from "@angular/core";
import { AxiosApiClient } from "@smallstack/axios-api-client";
import { Subscription } from "rxjs";
import { currentProjectId$ } from "../../../app.subscriptions";
import { AbstractTaskQueueStore } from "./abstract-taskqueue.store";

@Injectable({ providedIn: "root" })
export class ProjectTaskQueueStore extends AbstractTaskQueueStore implements OnDestroy {
  private subscription = new Subscription();
  constructor(axiosApiClient: AxiosApiClient) {
    super(axiosApiClient);
    this.subscription.add(
      currentProjectId$.subscribe(async () => {
        await this.reload();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

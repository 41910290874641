import { Injectable } from "@angular/core";
import { ResellerApiClient } from "../../api-client/services/api-client.factory";
import { AbstractTaskQueueStore } from "./abstract-taskqueue.store";

@Injectable({ providedIn: "root" })
export class UserTaskQueueStore extends AbstractTaskQueueStore {
  constructor(resellerApiClient: ResellerApiClient) {
    super(resellerApiClient);
  }
}

import { ChangeDetectionStrategy, Component, isDevMode } from "@angular/core";
import { AngularQueryDevtools } from "@tanstack/angular-query-devtools-experimental";

@Component({
  selector: "tanstack-query-devtools",
  imports: [AngularQueryDevtools],
  template: `@if (devMode) {
    <angular-query-devtools />
  }`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TanstackQueryDevToolsComponent {
  protected devMode = isDevMode();
}

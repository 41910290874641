import { ChangeDetectionStrategy, Component, signal } from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { MultiDialogRootComponent } from "@smallstack/common-components";
import { Logger } from "@smallstack/core-common";
import { TanstackQueryDevToolsComponent } from "@smallstack/typesystem-client";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "backoffice-root",
  templateUrl: "./app.component.html",
  imports: [MultiDialogRootComponent, TanstackQueryDevToolsComponent, RouterOutlet]
})
export class AppComponent {
  public loading = signal(true);

  constructor(router: Router) {
    Logger.info("AppComponent", "Root component loaded...");
    // void router.events.subscribe((ev) => {
    //   if (ev instanceof NavigationEnd && this.loading() === true) {
    document.getElementById("backoffice-loader")?.remove();
    this.loading.set(false);
    Logger.info("AppComponent", "Backoffice loader removed...");
    // }
    // });
  }
}

import { Routes } from "@angular/router";
import { Tab } from "@smallstack/cms-components";
import { ConfigurationStore, EmailTemplateStore } from "@smallstack/common-components";
import { FileStore } from "@smallstack/file-components";
import { F_MAILING, ServicePermissions } from "@smallstack/typesystem";
import { ProjectUserStore } from "@smallstack/user-components";
import { F_CUSTOM, F_OPTIN, F_USERS } from "../../app.constants";

import { CurrentCompanyGuard } from "../common/guards/current-company.guard";
import { CurrentProjectGuard } from "../common/guards/current-project.guard";
import { UserAuthenticatedGuard } from "../common/guards/user-authenticated.guard";
import { CompanyRoleStore } from "../common/store/company-role.store";
import { CompanyUserStore } from "../common/store/company-user.store";
import { ProjectRoleStore } from "../common/store/project-role.store";
import { ProjectTaskQueueStore } from "../common/store/project-taskqueue.store";
import { UserTaskQueueStore } from "../common/store/user-taskqueue.store";
import { CompanyFileStore } from "../files/stores/company-file.store";

import { CompanyInvitationsStore } from "./users/invitations/company-invitations.store";
import { ProjectInvitationsStore } from "./users/invitations/project-invitations.store";

const companyPath = "companies/:companyIdentifier";
const projectPath = `${companyPath}/projects/:projectIdentifier`;

export const backofficeRoutes: Routes = [
  {
    path: "",
    loadComponent: () => import("./backoffice/backoffice.component").then((m) => m.BackofficeComponent),
    canActivate: [UserAuthenticatedGuard],
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full"
      },
      {
        path: "profile",
        loadComponent: () => import("./profile/profile.component").then((m) => m.ProfileComponent),
        canActivate: [UserAuthenticatedGuard]
      },
      {
        path: "tasks",
        loadComponent: () => import("./taskqueue/taskqueue.component").then((m) => m.TaskQueueComponent),
        canActivate: [UserAuthenticatedGuard],
        data: {
          store: UserTaskQueueStore
        }
      },
      {
        path: "dashboard",
        loadComponent: () => import("./dashboard/dashboard.component").then((m) => m.DashboardComponent),
        canActivate: [UserAuthenticatedGuard]
      },
      {
        path: "projects",
        loadComponent: () =>
          import("./projects/projects-list/projects-list.component").then((m) => m.ProjectsListComponent),
        canActivate: [UserAuthenticatedGuard]
      },
      {
        path: "companies",
        loadComponent: () =>
          import("./companies/companies-list/companies-list.component").then((m) => m.CompaniesListComponent),
        canActivate: [UserAuthenticatedGuard]
      },
      {
        path: `${companyPath}`,
        redirectTo: `${companyPath}/projects`,
        pathMatch: "full"
      },
      {
        path: `${companyPath}/dashboard`,
        loadComponent: () =>
          import("./company-dashboard/company-dashboard.component").then((m) => m.CompanyDashboardComponent),
        canActivate: [UserAuthenticatedGuard, CurrentCompanyGuard]
      },
      {
        path: `${companyPath}/settings`,
        loadComponent: () =>
          import("../common/components/backoffice-tabs/backoffice-tabs.component").then(
            (m) => m.BackofficeTabsComponent
          ),
        canActivate: [UserAuthenticatedGuard, CurrentCompanyGuard],
        data: {
          links: [
            {
              link: "administration",
              title: "@@backoffice.company.administration.title"
            },
            {
              link: "roles",
              title: "@@backoffice.navigation.companyroles"
            }
          ] as Tab[]
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "administration"
          },
          {
            path: "administration",
            loadComponent: () =>
              import("./companies/administration/company-administration.component").then(
                (m) => m.CompanyAdministrationComponent
              ),
            canActivate: [UserAuthenticatedGuard, CurrentCompanyGuard]
          },
          {
            path: "roles",
            loadComponent: () => import("./roles/roles.component").then((m) => m.RolesComponent),
            canActivate: [UserAuthenticatedGuard, CurrentCompanyGuard],
            data: {
              roleStore: CompanyRoleStore,
              userStore: CompanyUserStore
            }
          }
        ]
      },
      {
        path: `${companyPath}/projects`,
        loadComponent: () =>
          import("./companies/projects/company-projects.component").then((m) => m.CompanyProjectsComponent),
        canActivate: [UserAuthenticatedGuard, CurrentCompanyGuard]
      },
      {
        path: `${companyPath}/invoices`,
        loadComponent: () =>
          import("./companies/company-invoices/company-invoices.component").then((m) => m.CompanyInvoicesComponent),
        canActivate: [UserAuthenticatedGuard, CurrentCompanyGuard]
      },
      {
        path: `${companyPath}/files`,
        canActivate: [UserAuthenticatedGuard, CurrentCompanyGuard],
        loadComponent: () => import("./files/files-backoffice.component").then((m) => m.FilesBackofficeComponent),
        data: {
          fileStore: CompanyFileStore
        }
      },
      {
        path: "reseller",
        loadChildren: () =>
          import("../reseller/reseller.module").then((resModule) => resModule.SmallstackResellerModule)
      },
      {
        path: `${companyPath}/users`,
        loadComponent: () => import("./users/users-backoffice.component").then((m) => m.UsersBackofficeComponent),
        canActivate: [UserAuthenticatedGuard, CurrentCompanyGuard],
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "management"
          },
          {
            path: "management",
            loadComponent: () =>
              import("../common/components/members/members.component").then((m) => m.MembersComponent),
            data: {
              userStore: CompanyUserStore,
              roleStore: CompanyRoleStore,
              title: "@@backoffice.members.company.members",
              subtitle: "@@backoffice.members.company.subtitle",
              enableForCompanyAdmin: true
            },
            canActivate: [UserAuthenticatedGuard, CurrentCompanyGuard]
          },
          {
            path: "invitations",
            loadComponent: () =>
              import("./users/invitations/invitations-backoffice.component").then(
                (m) => m.InvitationsBackofficeComponent
              ),
            canActivate: [UserAuthenticatedGuard, CurrentCompanyGuard],
            data: {
              invitationsStore: CompanyInvitationsStore,
              isProjectInvitation: false
            }
          }
        ]
      },
      {
        path: projectPath,
        pathMatch: "full",
        redirectTo: `${projectPath}/dashboard`
      },
      {
        path: `${projectPath}/dashboard`,
        loadComponent: () =>
          import("./projects/dashboard/dashboard.component").then((m) => m.ProjectDashboardComponent),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard]
      },
      {
        path: `${projectPath}/settings`,
        loadComponent: () =>
          import("../common/components/backoffice-tabs/backoffice-tabs.component").then(
            (m) => m.BackofficeTabsComponent
          ),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],

        data: {
          links: [
            {
              link: "details",
              title: "@@backoffice.project.details.title",
              permission: "tenant.admin"
            },
            {
              link: "module-activation",
              title: "@@backoffice.project.navigation.title",
              permission: "tenant.admin"
            }
          ] as Tab[]
        },
        children: [
          {
            path: "",
            redirectTo: "details",
            pathMatch: "full"
          },
          {
            path: "details",
            loadComponent: () =>
              import("./projects/details/project-details.component").then((m) => m.ProjectDetailsComponent),
            canActivate: [UserAuthenticatedGuard, CurrentProjectGuard]
          },
          {
            path: "module-activation",
            loadComponent: () =>
              import("./projects/module-activation/module-activation.component").then(
                (m) => m.ModuleActivationComponent
              ),
            canActivate: [UserAuthenticatedGuard, CurrentProjectGuard]
          }
        ]
      },
      {
        path: `${projectPath}/custom-backoffices/tester`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () =>
          import("./custom-backoffice/custom-backoffice-tester/custom-backoffice-tester.component").then(
            (m) => m.CustomBackofficeTesterComponent
          )
      },
      {
        path: `${projectPath}/custom-backoffices/tester/:htmltag`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () =>
          import("./custom-backoffice/custom-backoffice-tester/custom-backoffice-tester.component").then(
            (m) => m.CustomBackofficeTesterComponent
          )
      },
      {
        path: `${projectPath}/pdftemplates`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () =>
          import("./pdf-templates/pdf-template-list.component").then((m) => m.PdfTemplateListComponent)
      },
      {
        path: `${projectPath}/pdftemplates/new`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () =>
          import("./pdf-templates/pdf-template-editor/pdf-template-editor.component").then(
            (m) => m.PdfTemplateEditorComponent
          )
      },
      {
        path: `${projectPath}/pdftemplates/:pdfTemplateId`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () =>
          import("./pdf-templates/pdf-template-editor/pdf-template-editor.component").then(
            (m) => m.PdfTemplateEditorComponent
          )
      },
      {
        path: `${projectPath}/pim/products/new`,
        loadComponent: () =>
          import("./pim/products/editor/product-editor.component").then((m) => m.ProductEditorComponent),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "basicinfo"
          }
        ]
      },
      {
        path: `${projectPath}/pim/products/:productId`,
        loadComponent: () =>
          import("./pim/products/editor/product-editor.component").then((m) => m.ProductEditorComponent),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "basicinfo"
          },
          {
            path: "basicinfo",
            pathMatch: "full",
            loadComponent: () =>
              import("./pim/products/editor/product-editor-infos.component").then((m) => m.ProductEditorInfosComponent)
          },
          {
            path: "options",
            pathMatch: "full",
            loadComponent: () =>
              import("./pim/products/editor/product-editor-options.component").then(
                (m) => m.ProductEditorOptionsComponent
              )
          },
          {
            path: "variants",
            pathMatch: "full",
            loadComponent: () =>
              import("./pim/products/editor/product-editor-variants.component").then(
                (m) => m.ProductEditorVariantsComponent
              )
          },
          {
            path: "images",
            pathMatch: "full",
            loadComponent: () =>
              import("./pim/products/editor/product-editor-images.component").then(
                (m) => m.ProductEditorImagesComponent
              )
          }
        ]
      },
      {
        path: `${projectPath}/pim`,
        pathMatch: "full",
        redirectTo: "/products"
      },
      {
        path: `${projectPath}/pim/products`,
        pathMatch: "full",
        loadComponent: () => import("./pim/products/product-list.component").then((m) => m.ProductListComponent),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard]
      },
      {
        path: `${projectPath}/pim/pricezones`,
        loadComponent: () =>
          import("./pim/pricezone/list/price-zone-list.component").then((m) => m.PriceZoneListComponent),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard]
      },
      {
        path: `${projectPath}/pim/supplier`,
        loadComponent: () => import("./pim/supplier/list/supplier-list.component").then((m) => m.SupplierListComponent),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard]
      },
      {
        path: `${projectPath}/pim/orders`,
        loadComponent: () => import("./pim/orders/orders-list.component").then((m) => m.OrdersListComponent),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard]
      },
      {
        path: `${projectPath}/cms`,
        loadComponent: () => import("./cms/cms-backoffice.component").then((m) => m.CmsBackofficeComponent),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "pages"
          },
          {
            path: "pages",
            pathMatch: "full",
            loadComponent: () =>
              import("./cms/pages/cms-page-list-container.component").then((m) => m.CmsPageListContainerComponent)
          }
        ]
      },
      {
        path: `${projectPath}/cms/pages/new`,
        loadComponent: () =>
          import("./cms/pages/cms-page-list/editor/cms-page-editor.component").then((m) => m.CmsPageEditorComponent),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "info"
          }
        ]
      },
      {
        path: `${projectPath}/cms/pages/:pageId`,
        loadComponent: () =>
          import("./cms/pages/cms-page-list/editor/cms-page-editor.component").then((m) => m.CmsPageEditorComponent),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "info"
          },
          {
            path: "info",
            pathMatch: "full",
            loadComponent: () =>
              import("./cms/pages/cms-page-list/editor/cms-page-editor-infos.component").then(
                (m) => m.CmsPageEditorInfosComponent
              )
          },
          {
            path: "content",
            pathMatch: "full",
            loadComponent: () =>
              import("./cms/pages/cms-page-list/editor/cms-page-editor-content.component").then(
                (m) => m.CmsPageEditorContentComponent
              )
          }
        ]
      },
      {
        path: `${projectPath}/internationalization`,
        loadComponent: () =>
          import("../common/components/backoffice-tabs/backoffice-tabs.component").then(
            (m) => m.BackofficeTabsComponent
          ),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        data: {
          links: [
            {
              link: "./list",
              title: "@@backoffice.internationalization.title"
            },
            {
              link: "./translations",
              title: "@@backoffice.internationalization.localizations.title"
            }
          ] as Tab[]
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "list"
          },
          {
            path: "list",
            loadComponent: () =>
              import("./i18n/internationalization/internationalization-tab.component").then(
                (m) => m.InternationalizationTabComponent
              )
          },
          {
            path: "translations",
            loadComponent: () =>
              import("./i18n/translations/translations-backoffice.component").then(
                (m) => m.TranslationsBackofficeComponent
              )
          }
        ]
      },
      {
        path: `${projectPath}/applications/:applicationId`,
        loadChildren: () => import("../backoffices/applications/application.routes").then((r) => r.APPLICATION_ROUTES)
      },
      {
        path: `${projectPath}/workflow`,
        loadComponent: () =>
          import("./workflows/workflow-list/workflow-list.component").then((m) => m.WorkflowListComponent),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "list"
          },
          {
            path: "list",
            loadComponent: () =>
              import("./workflows/workflow-list/workflow-list.component").then((m) => m.WorkflowListComponent)
          }
        ]
      },
      {
        path: `${projectPath}/${F_MAILING}`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadChildren: () => import("./mailing/mailing.module").then((module) => module.SmallstackMailingModule)
      },
      {
        path: `${projectPath}/audit-log`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () => import("./audit-log/audit-log.component").then((m) => m.AuditLogComponent)
      },
      {
        path: `${projectPath}/calendars`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () =>
          import("./calendar/calendar-overview/calendar-overview.component").then((m) => m.CalendarOverviewComponent)
      },
      {
        path: `${projectPath}/contacts`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () =>
          import("../common/components/backoffice-tabs/backoffice-tabs.component").then(
            (m) => m.BackofficeTabsComponent
          ),
        data: {
          links: [
            {
              link: "./list",
              title: "@@backoffice.contacts.tab"
            },
            {
              link: "./customfields",
              title: "@@backoffice.contacts.customfield.tab",
              permission: ServicePermissions.CONTACTS_ADMIN
            }
          ] as Tab[]
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "list"
          },
          {
            path: "list",
            loadComponent: () =>
              import("./crm/contacts/contact-list/contact-list.component").then((m) => m.ContactListComponent)
          },
          {
            path: "customfields",
            loadComponent: () =>
              import("./crm/contacts/contact-custom-field-list/contact-custom-field-list.component").then(
                (m) => m.ContactCustomFieldListComponent
              )
          }
        ]
      },
      {
        path: `${projectPath}/tasks`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () => import("./taskqueue/taskqueue.component").then((m) => m.TaskQueueComponent),
        data: {
          store: ProjectTaskQueueStore
        }
      },
      {
        path: `${projectPath}/workflow/:workflowId`,
        loadComponent: () =>
          import("../common/components/backoffice-tabs/backoffice-tabs.component").then(
            (m) => m.BackofficeTabsComponent
          ),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        data: {
          links: [
            {
              link: "./detail",
              title: "@@backoffice.workflows.detail.tabheader"
            },
            {
              link: "./editor",
              title: "@@backoffice.workflows.editor.tabheader"
            }
          ] as Tab[]
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "detail"
          },
          {
            path: "detail",
            loadComponent: () =>
              import("./workflows/workflow-detail/workflow-detail.component").then((m) => m.WorkflowDetailComponent)
          },
          {
            path: "editor",
            loadComponent: () =>
              import("./workflows/workflow-editor/workflow-editor.component").then(
                (m) => m.BackofficeWorkflowEditorComponent
              )
          }
        ]
      },
      {
        path: `${projectPath}/checkin`,
        pathMatch: "full",
        redirectTo: "/visitors"
      },
      {
        path: `${projectPath}/checkin/events`,
        loadComponent: () =>
          import("./checkin/checkin-events/checkin-events.component").then((m) => m.CheckinEventsComponent),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard]
      },
      {
        path: `${projectPath}/checkin/visitors`,
        loadComponent: () =>
          import("./checkin/checkin-visitors/checkin-visitors.component").then((m) => m.CheckinVisitorsComponent),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard]
      },
      {
        path: `${projectPath}/checkin/apps`,
        loadComponent: () =>
          import("./checkin/checkin-apps/checkin-apps.component").then((m) => m.CheckinAppsComponent),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard]
      },
      {
        path: `${projectPath}/checkin/printerconfigurations`,
        loadComponent: () =>
          import("./checkin/printer-configuration/printer-configuration.component").then(
            (m) => m.PrinterConfigurationComponent
          ),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard]
      },
      {
        path: `${projectPath}/forms`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () => import("./forms/forms-list/forms-list.component").then((m) => m.FormsListComponent)
      },
      {
        path: `${projectPath}/applications`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () =>
          import("./applications/applications-list/applications-list.component").then(
            (m) => m.ApplicationsListComponent
          )
      },
      {
        path: `${projectPath}/files`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () => import("./files/files-backoffice.component").then((m) => m.FilesBackofficeComponent),
        data: {
          fileStore: FileStore
        }
      },
      {
        path: `${projectPath}/email`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () =>
          import("../common/components/backoffice-tabs/backoffice-tabs.component").then(
            (m) => m.BackofficeTabsComponent
          ),
        data: {
          links: [
            {
              link: "./sent",
              title: "@@backoffice.emails.sent.title"
            },
            {
              link: "./templates",
              title: "@@backoffice.emails.templates.title"
            },
            {
              link: "./setup",
              title: "@@backoffice.emails.setup.title",
              permission: "configuration.write"
            }
          ] as Tab[]
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "sent"
          },
          {
            path: "templates",
            loadComponent: () =>
              import("./emails/email-templates/email-templates.component").then((m) => m.EmailTemplatesComponent),
            data: {
              store: EmailTemplateStore
            }
          },
          {
            path: "sent",
            loadComponent: () => import("./emails/sent-emails/sent-emails.component").then((m) => m.SentEmailsComponent)
          },
          {
            path: "setup",
            loadComponent: () => import("./emails/email-setup/email-setup.component").then((m) => m.EmailSetupComponent)
          }
        ]
      },
      {
        path: `${projectPath}/members`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () =>
          import("../common/components/backoffice-tabs/backoffice-tabs.component").then(
            (m) => m.BackofficeTabsComponent
          ),
        data: {
          links: [
            {
              link: "./list",
              title: "@@backoffice.navigation.projectmembers"
            },
            {
              link: "./roles",
              title: "@@backoffice.navigation.projectroles"
            },
            {
              link: "./invitations",
              title: "@@backoffice.users.invitations.title"
            }
          ] as Tab[]
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "list"
          },
          {
            path: "list",
            loadComponent: () =>
              import("../common/components/members/members.component").then((m) => m.MembersComponent),
            data: {
              userStore: ProjectUserStore,
              roleStore: ProjectRoleStore,
              title: "@@backoffice.members.project.members",
              subtitle: "@@backoffice.members.project.subtitle",
              isProjectScope: true
            }
          },
          {
            path: "roles",
            loadComponent: () => import("./roles/roles.component").then((m) => m.RolesComponent),
            data: {
              roleStore: ProjectRoleStore,
              userStore: ProjectUserStore
            }
          },
          {
            path: "invitations",
            loadComponent: () =>
              import("./users/invitations/invitations-backoffice.component").then(
                (m) => m.InvitationsBackofficeComponent
              ),
            canActivate: [UserAuthenticatedGuard, CurrentCompanyGuard],
            data: {
              invitationsStore: ProjectInvitationsStore,
              isProjectInvitation: true
            }
          }
        ]
      },
      {
        path: `${projectPath}/${F_USERS}`,
        loadComponent: () =>
          import("./users/customer-backoffice/customer-backoffice.component").then(
            (m) => m.CustomerBackofficeComponent
          ),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard]
      },
      {
        path: `${projectPath}/export`,
        loadComponent: () => import("./impex/export.component").then((m) => m.ExportComponent),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard]
      },
      {
        path: `${projectPath}/import`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () => import("./impex/import.component").then((m) => m.ImportComponent)
      },
      {
        path: `${projectPath}/${F_OPTIN}`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () => import("./optins/project-optins.component").then((m) => m.ProjectOptinsComponent)
      },
      {
        path: `${projectPath}/devices`,
        loadChildren: () => import("./devices/devices.module").then((module) => module.SmallstackDevicesModule),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard]
      },
      {
        path: `${projectPath}/configurations`,
        loadChildren: () => import("./configurations/configurations.routes").then((r) => r.CONFIGURATIONS_ROUTES),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard]
      },
      {
        path: `${projectPath}/configuration`,
        loadComponent: () =>
          import("../common/components/backoffice-tabs/backoffice-tabs.component").then(
            (m) => m.BackofficeTabsComponent
          ),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        data: {
          links: [
            {
              link: "./config",
              title: "@@backoffice.configuration.title"
            },
            {
              link: "./advanced",
              title: "@@backoffice.configuration.advancedtitle"
            },
            {
              link: "./custom",
              title: "Benutzerdefiniert"
            }
          ] as Tab[]
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "config"
          },
          {
            path: "config",
            loadComponent: () =>
              import(
                "./configration/user-friendly-configuration/user-friendly-configuration-backoffice.component"
              ).then((m) => m.UserFriendlyConfigurationBackofficeComponent),
            data: {
              store: ConfigurationStore
            }
          },
          {
            path: "advanced",
            loadComponent: () =>
              import("./configration/configuration-backoffice.component").then(
                (m) => m.ConfigurationBackofficeComponent
              ),
            data: {
              store: ConfigurationStore
            }
          },
          {
            path: "custom",
            loadComponent: () =>
              import("./configration/custom-configuration-backoffice/custom-configuration-backoffice.component").then(
                (m) => m.CustomConfigurationBackofficeComponent
              )
          }
        ]
      },
      {
        path: `${projectPath}/service-token`,
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        loadComponent: () =>
          import("./service-token-backoffice/service-token-backoffice.component").then(
            (m) => m.ServiceTokenBackofficeComponent
          )
      },
      {
        path: `${projectPath}/${F_CUSTOM}-data`,
        loadComponent: () =>
          import("../common/components/backoffice-tabs/backoffice-tabs.component").then(
            (m) => m.BackofficeTabsComponent
          ),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard],
        data: {
          links: [
            {
              link: "./data",
              title: "@@backoffice.data.dataexplorer.tabheader"
            },
            {
              link: "./backoffices",
              title: "@@backoffice.custombackoffices.title"
            },
            {
              link: "./compound-widgets",
              title: "Eigene Widgets"
            }
          ] as Tab[]
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "backoffices"
          },
          {
            path: "data",
            pathMatch: "full",
            loadComponent: () =>
              import("./data/data-explorer/data-explorer.component").then((m) => m.DataExplorerComponent)
          },
          {
            path: "backoffices",
            pathMatch: "full",
            loadComponent: () =>
              import("./custom-backoffice/custom-backoffices-backoffice.component").then(
                (m) => m.CustomBackofficesBackofficeComponent
              )
          },
          {
            path: "compound-widgets",
            pathMatch: "full",
            loadComponent: () =>
              import("./compound-widgets/compound-widgets.component").then((m) => m.CompoundWidgetsComponent)
          }
        ]
      },
      {
        path: `${projectPath}/custom/:customModule`,
        loadComponent: () =>
          import("../custom-backoffices/custom-backoffice-container.component").then(
            (m) => m.CustomBackofficeContainerComponentComponent
          ),
        canActivate: [UserAuthenticatedGuard, CurrentProjectGuard]
      },
      {
        path: `${companyPath}`,
        redirectTo: `${companyPath}/overview`,
        pathMatch: "full"
      }
    ]
  },
  {
    path: "q/:action",
    loadComponent: () => import("./quick-actions/quick-actions.component").then((m) => m.QuickActionsComponent)
  },
  {
    path: "register",
    loadComponent: () => import("./register/backoffice-register.component").then((m) => m.BackofficeRegisterComponent)
  },
  {
    path: "login",
    loadComponent: () => import("./login/backoffice-login.component").then((m) => m.BackofficeLoginComponent)
  },
  {
    path: "passwordreset",
    loadComponent: () =>
      import("./passwordreset/password-reset.component").then((m) => m.BackofficePasswordResetComponent)
  },
  {
    path: "passwordreset/:token",
    loadComponent: () =>
      import("./passwordreset/password-reset.component").then((m) => m.BackofficePasswordResetComponent)
  },
  {
    path: "invitations/:invitationId",
    loadComponent: () =>
      import("./activate-invitations/activate-invitations.component").then((m) => m.ActivateInvitationsComponent),
    canActivate: [UserAuthenticatedGuard]
  },
  {
    path: "verifyemail",
    loadComponent: () => import("./verify-email/verify-email.component").then((m) => m.VerifyEmailComponent)
  },
  {
    path: "print",
    loadChildren: () => import("../../modules/pdfmake/pdfmake.module").then((module) => module.SmallstackPdfmakeModule)
  },
  {
    path: "not-found",
    loadComponent: () => import("../common/components/notfound/notfound.component").then((m) => m.NotFoundComponent)
  },
  {
    path: "error/:reason",
    loadComponent: () => import("./error/error.component").then((m) => m.ErrorComponent)
  },
  {
    path: "playground",
    loadChildren: () =>
      import("../../modules/playground/playground.module").then((playground) => playground.SmallstackPlaygroundModule),
    canActivate: [UserAuthenticatedGuard]
  },
  {
    path: "**",
    loadComponent: () => import("../common/components/notfound/notfound.component").then((m) => m.NotFoundComponent)
  }
];

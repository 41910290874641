import { CdkScrollable } from "@angular/cdk/scrolling";
import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { MAT_DIALOG_DATA, MatDialog, MatDialogContent, MatDialogTitle } from "@angular/material/dialog";
import { CalendarEntryDto, ContactDto } from "@smallstack/axios-api-client";
import {
  CalendarEntriesStore,
  CalendarEntryFormDialogComponent,
  CalendarEntryFormDialogData
} from "@smallstack/calendar-components";
import { LoadingElementDirective } from "@smallstack/common-components";
import { DateComponent, I18nComponent } from "@smallstack/i18n-components";
import { PageableStore } from "@smallstack/store";
import { StoreContainerComponent } from "@smallstack/store-components";
import { IconComponent } from "@smallstack/theme-components";
import { SearchByField, SearchByFieldMatcher, T_CALENDAR_ENTRIES, T_CONTACT } from "@smallstack/typesystem";
import { TypeIconPipe } from "@smallstack/typesystem-client";
import { addHours, startOfHour } from "date-fns";
import { firstValueFrom } from "rxjs";

export interface ContactCalendarEntriesDialogData {
  contact: ContactDto;
}

@Component({
  selector: "contact-calendar-entries-dialog",
  templateUrl: "./contact-calendar-entries-dialog.component.html",
  styleUrls: ["./contact-calendar-entries-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogTitle,
    IconComponent,
    MatCheckbox,
    CdkScrollable,
    MatDialogContent,
    StoreContainerComponent,
    I18nComponent,
    DateComponent,
    LoadingElementDirective,
    AsyncPipe,
    TypeIconPipe
  ]
})
export class ContactCalendarEntriesDialogComponent {
  public readonly T_CALENDAR_ENTRIES = T_CALENDAR_ENTRIES;
  public calendarEntriesSearchStore: PageableStore<CalendarEntryDto>;

  constructor(
    private calendarEntriesStore: CalendarEntriesStore,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: ContactCalendarEntriesDialogData
  ) {
    if (dialogData?.contact?.id) {
      void this.loadEntries(false);
    }
  }

  public togglePastEntries(checkboxEvent: MatCheckboxChange): void {
    void this.loadEntries(checkboxEvent.checked);
  }

  public editNewCalendarEntry(entry: CalendarEntryDto) {
    return async (): Promise<void> => {
      const dialogRef = this.matDialog.open(CalendarEntryFormDialogComponent, {
        data: {
          calendarEntry: entry
        } as CalendarEntryFormDialogData
      });
      await firstValueFrom(dialogRef.afterClosed());
      return this.calendarEntriesSearchStore.reload();
    };
  }

  public createNewCalendarEntry() {
    return async (): Promise<void> => {
      const dialogRef = this.matDialog.open(CalendarEntryFormDialogComponent, {
        data: {
          title: [
            { value: `Termin mit ${this.dialogData.contact?.firstName} ${this.dialogData.contact?.lastName} erstellen` }
          ],
          calendarEntry: {
            start: addHours(startOfHour(Date.now()), 1).valueOf(),
            end: addHours(startOfHour(Date.now()), 2).valueOf(),
            title: [{ value: `Termin mit ${this.dialogData.contact?.firstName} ${this.dialogData.contact?.lastName}` }],
            links: [
              {
                id: this.dialogData.contact.id,
                type: T_CONTACT
              }
            ]
          }
        } as CalendarEntryFormDialogData
      });
      await firstValueFrom(dialogRef.afterClosed());
      return this.calendarEntriesSearchStore.load();
    };
  }

  private async loadEntries(withPastEntries: boolean): Promise<void> {
    const fieldSearches: SearchByField[] = [
      { fieldname: "links.type", value: "contacts", matcher: SearchByFieldMatcher.EQUALS },
      { fieldname: "links.id", value: this.dialogData.contact?.id, matcher: SearchByFieldMatcher.EQUALS }
    ];
    if (!withPastEntries)
      fieldSearches.push({ fieldname: "end", value: Date.now(), matcher: SearchByFieldMatcher.GREATER_THAN_EQUALS });
    this.calendarEntriesSearchStore = this.calendarEntriesStore.createSearch({
      fieldSearches,
      logicalOperator: "and"
    });
    await this.calendarEntriesSearchStore.preload();
  }
}

import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FileExplorerDialogComponent, FileStore } from "@smallstack/file-components";
import {
  MarkdownEditorService,
  MarkdownEditorServiceOptions,
  MarkdownService,
  ToolbarIcon
} from "@smallstack/text-components";

@Injectable({ providedIn: "root" })
export class BackofficeMarkdownEditorService extends MarkdownEditorService {
  constructor(
    protected matDialog: MatDialog,
    protected fileStore: FileStore,
    markdownService: MarkdownService
  ) {
    super(markdownService);
  }

  public override getToolbarActions(options?: MarkdownEditorServiceOptions): Array<string | ToolbarIcon> {
    const selectFileAction = async (editor: EasyMDE) => {
      const fileUrl = await this.selectFile();
      if (fileUrl) {
        const pos = editor.codemirror.getCursor();
        editor.codemirror.setSelection(pos, pos);
        editor.codemirror.replaceSelection(`![](${fileUrl})`);
      }
    };

    return [
      ...super.getToolbarActions(options),
      {
        name: "smallstackImage",
        className: "fas fa-file-image",
        title: "Bild oder Datei aus Datei-Explorer einfügen",
        action: selectFileAction
      }
    ];
  }

  private async selectFile(): Promise<string> {
    const dialogRef = this.matDialog.open<FileExplorerDialogComponent, { fileStore: FileStore }>(
      FileExplorerDialogComponent,
      {
        width: "80%",
        height: "90%",
        data: { fileStore: this.fileStore }
      }
    );
    const selectedFile = await dialogRef.afterClosed().toPromise();
    return selectedFile?.url;
  }
}

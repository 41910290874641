import { AxiosApiClient, CreateTaskDto, JsonPatchDto, TaskDto, TasksApi } from "@smallstack/axios-api-client";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { PageableCrudStore } from "@smallstack/store";

export abstract class AbstractTaskQueueStore extends PageableCrudStore<TaskDto, CreateTaskDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<TaskDto>> {
    const res = await this.axiosApiClient.get(TasksApi).getTasks(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<TaskDto> {
    const res = await this.axiosApiClient.get(TasksApi).getTask({ id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<any> {
    const res = await this.axiosApiClient.get(TasksApi).deleteTask({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(TasksApi).deleteManyTasks({ ids });
    return res.data;
  }
  protected async createModel(task: CreateTaskDto): Promise<TaskDto> {
    const res = await this.axiosApiClient.get(TasksApi).createTask({ createTask: task });
    return res.data;
  }
  protected async patchModel(id: string, task: Partial<TaskDto> | Array<JsonPatchDto>): Promise<TaskDto> {
    const res = await this.axiosApiClient.get(TasksApi).patchTask({ id, task });
    return res.data;
  }
  protected async putModel(task: TaskDto): Promise<TaskDto> {
    const res = await this.axiosApiClient.get(TasksApi).putTask({ id: task.id, task });
    return res.data;
  }
}

import "zone.js";

import { enableProdMode } from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";
import { BrowserTracing, captureException, init, instrumentAngularRouting } from "@sentry/angular-ivy";
import { Logger, reseller } from "@smallstack/core-common";
import { AppComponent } from "./app/app.component";
import { providers } from "./app/app.providers";
import { smallstackApiUrl, smallstackUrl } from "./app/app.subscriptions";
import { reseller$ } from "./app/modules/common/store/reseller.store";
import { environment } from "./environments/environment";

Logger.info("Main", "Bootstrapping backoffice...");
if (environment.production) {
  enableProdMode();
}
if (environment.sentryDns) {
  Logger.info("Main", "Setting up Sentry...");
  init({
    dsn: environment.sentryDns,
    release: environment.version,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ["localhost", "https://api.smallstack.com", "https://api-sandbox.smallstack.com"],
        routingInstrumentation: instrumentAngularRouting
      })
    ],
    tracesSampleRate: 0.05,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1
  });
}

Logger.on("error", (moduleName: string, message: string, stacktrace: unknown) => {
  if (stacktrace) captureException(stacktrace);
  else {
    const loggerError: Error = new Error(message);
    loggerError.name = moduleName;
    captureException(loggerError);
  }
});

smallstackApiUrl.next(environment.apiUrl);
smallstackUrl.next(window.location.protocol + "//" + window.location.host);
reseller$.next(reseller);

// some debug debug
Logger.info("Main", `Base URL: ${smallstackUrl.value}`);
Logger.info("Main", `Endpoint: ${smallstackApiUrl.value}`);
Logger.debug("Main", `Reseller      : ${JSON.stringify(reseller)}`);

await bootstrapApplication(AppComponent, { providers }).catch((err) => {
  Logger.error("Main", err);
  captureException(err);
  throw err;
});

import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {
  EnvironmentProviders,
  ErrorHandler,
  Injector,
  LOCALE_ID,
  NgZone,
  Provider,
  inject,
  provideAppInitializer
} from "@angular/core";
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS, provideDateFnsAdapter } from "@angular/material-date-fns-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import { Router, provideRouter, withRouterConfig } from "@angular/router";
import { TraceService } from "@sentry/angular-ivy";
import { addAllWidgets } from "@smallstack/all-widgets";
import { AxiosApiClient } from "@smallstack/axios-api-client";
import { BackofficeIcons } from "@smallstack/backoffice-shared";
import { CalendarStore } from "@smallstack/calendar-components";
import { EnvironmentService, ErrorHandlerService, PushNotificationConfiguration } from "@smallstack/client-common";
import { CMS_MODULE_INIT, addCmsFormInputComponents } from "@smallstack/cms-components";
import {
  ApiConfigurationService,
  ContextService,
  MsalPublicClientApplicationService,
  RESELLER_API_CLIENT,
  RouterUtilService,
  StoreRegistry,
  TRANSLATION_HELPER,
  UrlResolverKeys,
  UrlResolverService
} from "@smallstack/common-components";
import { IOC, Logger, reseller } from "@smallstack/core-common";
import { CRM_INIT_PROVIDER, ContactCustomFieldStore, ContactsStore } from "@smallstack/crm-components";
import { addDefaultFormInputComponents } from "@smallstack/form-components";
import { addDefaultPdfFormInputComponents } from "@smallstack/form-components/pdf";
import { LocaleService, NotificationService, TranslationLoader, TranslationStore } from "@smallstack/i18n-components";
import {
  loadApplication,
  loadBackoffice,
  loadBackofficeAuditlog,
  loadCalendar,
  loadCloud,
  loadCommon,
  loadComponents,
  loadCrm,
  loadDashboard,
  loadDevices,
  loadFiles,
  loadForms,
  loadI18n,
  loadInvoice,
  loadMailings,
  loadTodo,
  loadUser,
  loadWidgets
} from "@smallstack/language-packs";
import { LINK_MODULE_INIT } from "@smallstack/link-components";
import { PRODUCT_MODULE_INIT } from "@smallstack/product-components";
import { MarkdownEditorService } from "@smallstack/text-components";
import { IconThemeService, ThemeService } from "@smallstack/theme-components";
import { TODO_MODULE_INIT } from "@smallstack/todo-components";
import { TodoConfigurationService } from "@smallstack/todo-shared";
import { AjvFactory } from "@smallstack/typesystem";
import { DraftService, TypeService, typesystemClientProviders } from "@smallstack/typesystem-client";
import {
  MsalLoginService,
  MyMessageThreadsStore,
  MyMessagesStore,
  MyPermissionsStore,
  NoteStore,
  ProfileProperty,
  USER_MODULE_INIT,
  UserService
} from "@smallstack/user-components";
import {
  EXTENSION_SLOT_COMPONENT_PROVIDER,
  INPUT_WIDGETS_TRANSLATION_STORE,
  SchemaFormInputsRegistry,
  WidgetRegistry
} from "@smallstack/widget-core";
import { de } from "date-fns/locale";
import { environment } from "../environments/environment";
import { PROJECT_LOCALE_SERVICE } from "./app.constants";
import { currentProjectId$, smallstackApiUrl } from "./app.subscriptions";
import {
  ApiClientFactory,
  ProjectApiClient,
  ResellerApiClient
} from "./modules/api-client/services/api-client.factory";
import { backofficeRoutes } from "./modules/backoffices/backoffice.routes";
import { BackofficeContactCustomFieldStore } from "./modules/backoffices/crm/contacts/backoffice-contact-custom-field.store";
import { ContactListComponent } from "./modules/backoffices/crm/contacts/contact-list/contact-list.component";
import { BackofficeContactStore } from "./modules/backoffices/crm/contacts/contact.store";
import { InitService } from "./modules/backoffices/init.service";
import { BackofficeDraftService } from "./modules/common/services/backoffice-draft.service";
import { BackofficeMarkdownEditorService } from "./modules/common/services/backoffice-markdown-editor.service";
import { BackofficeTodoConfigurationService } from "./modules/common/services/backoffice-todo-configuration.service";
import { BackofficeUserService } from "./modules/common/services/backoffice-user.service";
import { BackofficeWebComponentService } from "./modules/common/services/backoffice-web-component.service";
import { ResellerContextService } from "./modules/common/services/reseller-context.service";
import { ProjectTranslationStore } from "./modules/common/store/project-translation.store";
import { ResellerTranslationStore } from "./modules/common/store/reseller-translation.store";
import { reseller$ } from "./modules/common/store/reseller.store";
import { registerBackofficeWidgets } from "./widgets/register-backoffice-widgets";

function initBackoffice(): void {
  // register() has to be called before detectLocales()
  const translationLoader = inject(TranslationLoader);
  translationLoader.register([
    loadApplication,
    loadBackoffice,
    loadCalendar,
    loadCommon,
    loadComponents,
    loadDashboard,
    loadDevices,
    loadFiles,
    loadForms,
    loadI18n,
    loadUser,
    loadCloud,
    loadBackofficeAuditlog,
    loadMailings,
    loadWidgets,
    loadCrm,
    loadInvoice,
    loadTodo
  ]);
  const localeService = inject(LocaleService);
  void localeService.getAllLocales().then(() => {
    return localeService.detectLocale();
  });

  const environmentService = inject(EnvironmentService);
  environmentService.set(environment);
  (window as any).ngZone = inject(NgZone);
  const iconThemeService = inject(IconThemeService);
  iconThemeService.setAliases(BackofficeIcons);

  // register widgets
  const widgetRegistry = inject(WidgetRegistry);
  addAllWidgets(widgetRegistry);
  registerBackofficeWidgets(widgetRegistry);
  widgetRegistry.registerWidget(ContactListComponent);

  // close dialogs on navigate
  inject(RouterUtilService).enableDialogAutoClosing();

  // add important urls to urlResolverService
  inject(UrlResolverService).addResolver(UrlResolverKeys.API_URL, async () => environment.apiUrl);
  Logger.info("AppModule", "Backoffice application module loaded...");
  inject(ContextService).updateContext({ resellerId: reseller.id });

  inject(ApiConfigurationService).configuration$.next({
    apiUrl: () => smallstackApiUrl.value
  });

  // get current user icon theme
  void inject(BackofficeUserService)
    .getProfileProperty$(ProfileProperty.ICON_THEME)
    .subscribe((theme) => {
      if (theme) iconThemeService.theme$.next(theme);
      iconThemeService.size$.next(48);
    });

  // subscribe to reseller
  if (reseller?.cooperateIdentity?.theme) inject(ThemeService).$currentTheme.next(reseller.cooperateIdentity.theme);
  else inject(ThemeService).$currentTheme.next(inject(ThemeService).getDefaultTheme());

  inject(InitService).registerStores();

  // we need those on our beloved IOC so that we can access them in web components (which might not be an angular component at all)
  IOC.register("localeService", localeService);
  IOC.register("apiClientFactory", inject(ApiClientFactory));
  IOC.register("tokenService", inject(UserService));
  IOC.register("notificationService", inject(NotificationService));
  IOC.register("webComponentService", inject(BackofficeWebComponentService));
  IOC.register("storeRegistry", inject(StoreRegistry));

  // msal login
  inject(MsalLoginService).watchLoginRedirect();

  environmentService.set(environment);

  addDefaultFormInputComponents(inject(SchemaFormInputsRegistry));
  addDefaultPdfFormInputComponents(inject(SchemaFormInputsRegistry));
  addCmsFormInputComponents(inject(SchemaFormInputsRegistry));
}

export const providers: Array<Provider | EnvironmentProviders> = [
  provideRouter(backofficeRoutes, withRouterConfig({ paramsInheritanceStrategy: "always" })),
  provideAppInitializer(initBackoffice),
  provideAnimations(),
  provideDateFnsAdapter(),
  CRM_INIT_PROVIDER,
  USER_MODULE_INIT,
  PRODUCT_MODULE_INIT,
  LINK_MODULE_INIT,
  CMS_MODULE_INIT,
  ...TODO_MODULE_INIT,
  BackofficeUserService,
  {
    provide: MsalPublicClientApplicationService,
    useFactory: (apiClient: ResellerApiClient) => {
      return new MsalPublicClientApplicationService(apiClient);
    },
    deps: [ResellerApiClient]
  },
  {
    provide: AxiosApiClient,
    useFactory: (apiClientFactory: ApiClientFactory) => {
      return apiClientFactory.project();
    },
    deps: [ApiClientFactory]
  },
  {
    provide: MsalLoginService,
    useFactory: (
      notificationService: NotificationService,
      msalPublicClientApplicationService: MsalPublicClientApplicationService,
      router: Router,
      axiosApiClient: ResellerApiClient,
      userService: UserService
    ) => {
      const msalLoginService = new MsalLoginService(
        notificationService,
        msalPublicClientApplicationService,
        router,
        axiosApiClient,
        userService
      );
      return msalLoginService;
    },
    deps: [NotificationService, MsalPublicClientApplicationService, Router, AxiosApiClient, UserService]
  },
  {
    provide: UserService,
    useExisting: BackofficeUserService
  },
  {
    provide: LocaleService,
    useFactory: (apiClient: ResellerApiClient) => {
      return new LocaleService(apiClient);
    },
    deps: [ResellerApiClient]
  },
  {
    provide: PROJECT_LOCALE_SERVICE,
    useFactory: (apiClientFactory: ApiClientFactory) => {
      return new LocaleService(apiClientFactory.project());
    },
    deps: [ApiClientFactory]
  },
  ResellerTranslationStore,
  {
    provide: TranslationStore, // the default translation store for the backoffice is the one from the reseller, aka the backoffice tenant
    useExisting: ResellerTranslationStore
  },
  {
    provide: ErrorHandler,
    useExisting: ErrorHandlerService
    // useValue: createErrorHandler({
    //   showDialog: false,
    //   logErrors: true
    // })
  },
  {
    provide: TraceService,
    deps: [Router]
  },
  {
    provide: TRANSLATION_HELPER,
    useExisting: TranslationStore
  },
  {
    provide: MyPermissionsStore,
    useFactory: (apiClientFactory: ApiClientFactory) => {
      return new MyPermissionsStore(apiClientFactory.reseller());
    },
    deps: [ApiClientFactory]
  },
  {
    provide: RESELLER_API_CLIENT,
    useFactory: (apiClientFactory: ApiClientFactory) => {
      return apiClientFactory.reseller();
    },
    deps: [ApiClientFactory]
  },
  {
    provide: DraftService,
    useClass: BackofficeDraftService
  },
  {
    provide: AjvFactory,
    useFactory: (typeService: TypeService) => {
      return new AjvFactory(typeService);
    },
    deps: [TypeService]
  },
  {
    provide: TodoConfigurationService,
    useClass: BackofficeTodoConfigurationService
  },
  {
    provide: PushNotificationConfiguration,
    useFactory: (contextService: ContextService) => {
      return {
        context: {
          tenantId: () => reseller$.value?.id,
          resellerId: () => reseller$.value?.id,
          authTenantId: () => reseller$.value?.id,
          token: () => contextService.context().token
        }
      };
    },
    deps: [ContextService]
  },
  ...typesystemClientProviders,
  EXTENSION_SLOT_COMPONENT_PROVIDER,
  {
    provide: MyMessageThreadsStore,
    useFactory: (injector: Injector, resellerContextService: ResellerContextService) => {
      return new MyMessageThreadsStore(injector, resellerContextService);
    },
    deps: [Injector, ResellerContextService]
  },
  {
    provide: MyMessagesStore,
    useFactory: (injector: Injector, resellerContextService: ResellerContextService) => {
      return new MyMessagesStore(injector, resellerContextService);
    },
    deps: [Injector, ResellerContextService]
  },
  { provide: LOCALE_ID, useValue: "de-DE" },
  { provide: MAT_DATE_LOCALE, useValue: de },
  { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
  { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
  { provide: ContactCustomFieldStore, useClass: BackofficeContactCustomFieldStore },
  { provide: ContactsStore, useExisting: BackofficeContactStore },
  { provide: INPUT_WIDGETS_TRANSLATION_STORE, useExisting: ProjectTranslationStore },
  {
    provide: CalendarStore,
    useFactory: (projectApiClient: ProjectApiClient) => new CalendarStore(projectApiClient),
    deps: [ProjectApiClient]
  },
  provideHttpClient(withInterceptorsFromDi()),
  {
    provide: NoteStore,
    useFactory: (axiosApiClient: AxiosApiClient) => {
      const store = new NoteStore(axiosApiClient);
      void currentProjectId$.subscribe(() => store.reset());
      return store;
    },
    deps: [AxiosApiClient]
  },
  {
    provide: MarkdownEditorService,
    useClass: BackofficeMarkdownEditorService
  }
];

import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { AxiosApiClient, CronjobDto, CronjobsApi } from "@smallstack/axios-api-client";
import { PageableCrudStore } from "@smallstack/store";
import { currentProjectId$ } from "../../../app.subscriptions";

@Injectable({ providedIn: "root" })
export class CronjobStore extends PageableCrudStore<CronjobDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
    currentProjectId$.subscribe(() => {
      return this.reset();
    });
  }

  protected async loadModelById(id: string): Promise<CronjobDto> {
    const res = await this.axiosApiClient.get(CronjobsApi).getCronjob({ id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<void> {
    const res = await this.axiosApiClient.get(CronjobsApi).deleteCronjob({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(CronjobsApi).deleteManyCronjobs({ ids });
    return res.data;
  }
  protected async createModel(
    model: Omit<CronjobDto, "id" | "context" | "createdAt" | "lastUpdatedAt">
  ): Promise<CronjobDto> {
    const res = await this.axiosApiClient.get(CronjobsApi).createCronjob({ cronjob: model });
    return res.data;
  }
  protected async patchModel(id: string, model: any): Promise<CronjobDto> {
    const res = await this.axiosApiClient.get(CronjobsApi).patchCronjob({ id, cronjob: model });
    return res.data;
  }
  protected async putModel(model: CronjobDto): Promise<CronjobDto> {
    const res = await this.axiosApiClient.get(CronjobsApi).putCronjob({ cronjob: model, id: model.id });
    return res.data;
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<CronjobDto>> {
    const res = await this.axiosApiClient.get(CronjobsApi).getCronjobs(query);
    return res.data;
  }
}

import { Injectable } from "@angular/core";
import { AxiosApiClient } from "@smallstack/axios-api-client";
import { ContactsStore } from "@smallstack/crm-components";
import { currentProjectId$ } from "../../../../app.subscriptions";

@Injectable({ providedIn: "root" })
export class BackofficeContactStore extends ContactsStore {
  constructor(axiosApiClient: AxiosApiClient) {
    super(axiosApiClient);
    void currentProjectId$.subscribe(() => this.reset());
  }
}

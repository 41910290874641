import { Injectable } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { WebComponentService } from "@smallstack/common-components";
import { NotificationService, TranslationStore } from "@smallstack/i18n-components";
import { ProjectStore } from "../store/project.store";

@Injectable({ providedIn: "root" })
export class BackofficeWebComponentService implements WebComponentService {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private projectStore: ProjectStore,
    private translationStore: TranslationStore
  ) {}

  public getNavigationParams(): Params {
    return this.activatedRoute?.snapshot?.queryParams;
  }

  public async navigateFromWebComponent(targetPath: string, queryParams?: Params): Promise<void> {
    if (typeof targetPath !== "string") return;
    if (targetPath.startsWith("/")) targetPath = targetPath.substr(1);
    if (targetPath.endsWith("/")) targetPath.substr(0, targetPath.length - 1);
    if (this.projectStore.currentProject() === undefined)
      return this.notificationService.popup.error(
        this.translationStore.translateByKey("backoffice.custombackoffices.wcs.errors.notinproject")
      );
    const navigateCommands: string[] = [
      ...(await this.projectStore.getBackofficeUrl$(this.projectStore.currentProject()?.id)).split("/"),
      ...targetPath.split("/")
    ];
    this.router.navigate(navigateCommands, { queryParams, queryParamsHandling: "merge" }).catch(() => {
      this.notificationService.popup.error(
        this.translationStore.translateByKey("backoffice.custombackoffices.wcs.errors.routenotfound")
      );
    });
  }
}

import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Provider } from "@angular/core";
import {
  FormService,
  SchemaFormBaseWidget,
  WidgetEditorRendererComponent,
  WidgetTreeService
} from "@smallstack/widget-core";
import { CmsPageAddWidgetComponent } from "../../cms-page-add-widget/cms-page-add-widget.component";

@Component({
  selector: "smallstack-cms-form-input",
  templateUrl: "./cms-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WidgetTreeService],
  imports: [WidgetEditorRendererComponent, CmsPageAddWidgetComponent, AsyncPipe]
})
export class CmsFormInputComponent extends SchemaFormBaseWidget {
  protected additionalProviders: Provider[] = [{ provide: FormService, useValue: undefined }];

  constructor(widgetTreeService: WidgetTreeService) {
    super();
    widgetTreeService.editMode.set(true);
  }
}

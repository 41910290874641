import { Injectable } from "@angular/core";
import { AxiosApiClient } from "@smallstack/axios-api-client";
import { ContactCustomFieldStore } from "@smallstack/crm-components";
import { currentProjectId$ } from "../../../../app.subscriptions";

@Injectable({ providedIn: "root" })
export class BackofficeContactCustomFieldStore extends ContactCustomFieldStore {
  constructor(axiosApiClient: AxiosApiClient) {
    super(axiosApiClient);
    void currentProjectId$.subscribe(() => this.reset());
  }
}

import { Injectable } from "@angular/core";
import { AuditLogApi, AuditLogRecordDto, AuditLogRecordPageDto, AxiosApiClient } from "@smallstack/axios-api-client";
import { ApiQueryRequest } from "@smallstack/api-shared";
import { PageableStore } from "@smallstack/store";
import { currentProjectId$ } from "../../../app.subscriptions";

@Injectable({ providedIn: "root" })
export class AuditLogStore extends PageableStore<AuditLogRecordDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
    currentProjectId$.subscribe(() => this.reset());
  }

  protected async loadModels(query: ApiQueryRequest): Promise<AuditLogRecordPageDto> {
    const res = await this.axiosApiClient.get(AuditLogApi).getAuditLog(query);
    return res.data;
  }
}
